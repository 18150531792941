exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-architecture-js": () => import("./../../../src/pages/portfolio/architecture.js" /* webpackChunkName: "component---src-pages-portfolio-architecture-js" */),
  "component---src-pages-portfolio-events-js": () => import("./../../../src/pages/portfolio/events.js" /* webpackChunkName: "component---src-pages-portfolio-events-js" */),
  "component---src-pages-portfolio-index-js": () => import("./../../../src/pages/portfolio/index.js" /* webpackChunkName: "component---src-pages-portfolio-index-js" */),
  "component---src-pages-portfolio-nature-js": () => import("./../../../src/pages/portfolio/nature.js" /* webpackChunkName: "component---src-pages-portfolio-nature-js" */),
  "component---src-pages-portfolio-people-js": () => import("./../../../src/pages/portfolio/people.js" /* webpackChunkName: "component---src-pages-portfolio-people-js" */),
  "component---src-pages-portfolio-pets-js": () => import("./../../../src/pages/portfolio/pets.js" /* webpackChunkName: "component---src-pages-portfolio-pets-js" */),
  "component---src-pages-portfolio-travel-js": () => import("./../../../src/pages/portfolio/travel.js" /* webpackChunkName: "component---src-pages-portfolio-travel-js" */),
  "component---src-pages-thankyou-index-js": () => import("./../../../src/pages/thankyou/index.js" /* webpackChunkName: "component---src-pages-thankyou-index-js" */)
}

